import Basket from 'Components/MainScreen/AppBody/ShopMenu/Basket';
import ItemList from 'Components/MainScreen/AppBody/ShopMenu/ItemList';
import SideMenu from 'Components/MainScreen/AppBody/ShopMenu/SideMenu';
import React, {useState} from 'react'
import { useScreenType } from '../../../hooks/useScreenType';
import style from './style.module.css';

const AppBodyLayout = ({ basketIsOpen, closeBasket, isCartItemUpdating, isPackItemUpdating, toggleBasket }) => {
    const screenType = useScreenType();

    if(screenType === '3-cols'){
        return(
            <div className={style.Container}>
                <div className={style.Cols_3}>
                    <Basket />
                    <ItemList toggleBasket={toggleBasket}/>
                    <SideMenu />
                </div>
            </div>
        )
    }
    return(
        <div className={style.Container}>
            <div className={style.FullScreen}>
            {
                basketIsOpen || isCartItemUpdating || isPackItemUpdating
                ?
                    <Basket basketIsOpen={basketIsOpen} closeBasket={closeBasket}/>
                :
                    <>
                        <ItemList toggleBasket={toggleBasket}/>
                        <SideMenu />
                    </>
            }
            </div>
        </div>
    )

}

export default AppBodyLayout
